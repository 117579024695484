@import "../../assets/styles/variables";

.wrapper {
  border: 2px solid $primary;

  .header {
    background-color: $primary;
    padding: 1rem;
    color: white;
    font-family: 'Noto Serif JP', serif;
  }

  .body {
    padding: 1rem;
  }

  .grey {
    padding: 1rem;
    background-color: $secondary;
  }
}