@import "../../assets/styles/variables";

.products {
  position: relative;

  .header {
    margin-top: -4rem;

    @include breakpoint(untilTablet) {
      margin-top: -3rem;
    }

    .container-fluid {
      padding: 0;
      height: 190px;
      position: relative;

      &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.6);
      }

      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
      }

      .text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 4;
        color: white;
        font-size: 28px;
        padding: 0 20px 10px;
        font-weight: 500;
        letter-spacing: 0.1rem;
        font-family: 'Noto Serif JP', serif;
        text-align: center;

        @include breakpoint(untilTablet) {
          width: 100%;
        }

        &:after {
          content: "";
          width: 100px;
          height: 2px;
          background: #FFF;
          display: inline-block;
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          bottom: 0;
          z-index: 50;
        }
      }
    }
  }

  .loadMore {
    display: flex;
    justify-content: flex-end;

    .btn {
      border-radius: 20px;
      padding: 4px 40px !important;
      text-transform: uppercase;
    }
  }

  .floatingText {
    position: sticky;
    left: 0;
    top: 0;
    height: 100%;
    width: 60px;

    .text {
      padding: 2.5rem 0.5rem;
      background-color: $primary;
      font-weight: bold;
      writing-mode: vertical-rl;
      border-bottom-right-radius: 1.25rem;
      font-size: 1.75rem;
      min-height: 190px;
      text-align: center;
    }

    @include breakpoint(untilTablet) {
      position: static;
      width: max-content;

      .text {
        writing-mode: horizontal-tb;
        padding: 1rem 2rem;
        font-size: 1rem;
        min-height: 0;
      }
    }
  }

  >.container {

    @include breakpoint(untilTablet) {
      margin-top: 1rem;
    }
  }

  .productsWrapper,
  .skeletonWrapper {
    @include breakpoint(untilTablet) {
      width: 100%;
      margin-top: 1rem;
    }
  }

  .titlePage {
    text-align: center;
    padding: 0.5rem 1rem;
    background-color: $primary;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 2rem;

    @include breakpoint(untilTablet) {
      font-size: 1.25rem;
    }
  }

  .productsOption {
    .sort {
      width: 250px;
      display: flex;
      align-items: center;

      .text {
        white-space: nowrap;
        margin-right: 1rem;
      }
    }
  }

  .productItems {
    .item {
      margin-bottom: 1rem;
      cursor: pointer;

      .image {

        img {
          width: 100%;
          height: 180px;
          object-fit: cover;
        }
      }

      .info {
        padding: 10px;
      }

      .title,
      .address,
      .price,
      .desc {
        margin-bottom: 0.5rem;
      }

      @include breakpoint(laptop) {
        .info {
          min-height: 170px;
        }
      }

      .address {
        color: rgb(105, 105, 105);
        font-weight: 500;
      }

      .title,
      .desc {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .desc {

        div,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
          font-size: 14px !important;
        }
      }

      .desc {
        height: 45px;
        position: relative;

        // &::before {
        //   content: '';
        //   background: linear-gradient(to bottom, transparent, white);
        //   position: absolute;
        //   bottom: 0;
        //   width: 100%;
        //   height: 10px;
        // }
      }

      .buttonWrapper {
        margin: -1rem;
        margin-top: 0;
        padding-top: 0.5rem;
      }

      .title {
        font-weight: bold;
        -webkit-line-clamp: 2;
      }

      .btn {
        font-weight: bold;
      }
    }
  }
}