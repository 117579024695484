@import "../../assets/styles/variables";

.cart {
  position: fixed;
  bottom: 80px;
  right: 120px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  z-index: 20;

  @include breakpoint(untilTablet) {
    right: 80px;
    bottom: 50px;
  }

  .cartWrapper {
    background-color: white;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin-bottom: 1.5rem;
    padding: 1rem;
    max-width: 450px;
    min-width: 300px;

    @include breakpoint(untilTablet) {
      max-width: 100%;
      min-width: 100%;
      margin-right: -3.5rem;
    }

    .items {
      min-height: 300px;
    }

    .item {
      margin-bottom: 1.5rem;

      .info {
        .title {
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }

    .image {
      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    .remove {
      cursor: pointer;
    }
  }

  .cartButton {
    display: inline-block;
    position: relative;
    background-color: #FF9A33;
    padding: 0.5rem;
    border-radius: 50%;
    font-size: 1.75rem;
    height: 60px;
    width: 60px;
    text-align: center;
    // box-shadow: 0 0 5px 5px rgba($color: #969696, $alpha: 0.2);
    color: white;


    .cartTotal {
      position: absolute;
      font-size: 0.95rem;
      top: -1rem;
      right: -1rem;
      height: 2rem;
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      border-radius: 50%;
      background-color: #FFF9E5;
      color: black;
    }

    @include breakpoint(untilTablet) {
      height: 40px;
      width: 40px;
      font-size: 1.1rem;

      .cartTotal {
        font-size: .65rem;
        width: 1.25rem;
        height: 1.25rem;
        right: -.5rem;
        top: -.5rem;
      }
    }
  }
}