.of-cover {
  object-fit: cover;
  object-position: center;
}

.f-11px {
  font-size: 11px;
}

.position-sticky {
  position: sticky;
  top: 0;
}

.modal-header {
  border-bottom: none !important;
  padding-bottom: 0;
}

.modal-footer {
  border-top: none !important;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.top {
  position: fixed;
  bottom: 80px;
  right: 40px;
  z-index: 100;
  cursor: pointer;

  img {
    width: 60px;
    height: 60px;
  }

  @include breakpoint(untilTablet) {
    bottom: 50px;
    right: 20px;

    img {
      width: 40px;
      height: 40px;
    }
  }
}

.btn-primary {
  color: white;

  &:hover,
  &:focus {
    color: white;
  }
}

.btn {
  border-radius: 0;
}

#breadcrumbs {
  font-size: 12px;
  margin: 0 0 30px;

  a {
    color: $primary;
  }
}

@include breakpoint(tablet) {
  #breadcrumbs {
    text-align: right;
    margin: 0 0 60px;
  }
}


.react-calendar {
  width: 200px;
  position: absolute;

  &.hide {
    display: none;
  }
}

.react-calendar__navigation button {
  min-width: 24px;
}

.react-calendar__tile {
  line-height: 10px;
}

.form-control[readonly] {
  background-color: white;
  cursor: pointer;
}