@import "../../assets/styles/variables";

.footer {
  background-color: $primary;
  color: white;

  .container {
    padding: 50px 0;

    .leftFooter {
      padding: 80px 100px;
      padding-left: 80px;
      padding-right: 50px;
    }

    .logo {
      padding-left: 50px;
      padding-bottom: 20px;
      border-bottom: 1px solid white;

      img {
        width: auto;
        height: 40px;
      }
    }

    .sns {
      display: flex;
      padding-left: 50px;

      .item {
        padding-top: 10px;
        padding-right: 10px;

        img {
          width: 20px;
          height: auto;
        }
      }
    }

    @include breakpoint(mobileonly) {
      padding: 0;

      .row {
        margin-right: 0.5rem;
      }

      .logo {
        padding: 0 20% 15px;
      }

      .leftFooter {
        padding: 80px 30px 20px 30px;
      }

      .sns {
        justify-content: center;
        padding: 0;
      }
    }

    .secondSection {
      padding-left: 2.25rem;
      margin-right: -3.15rem;
    }

    .firstBox .list {
      border-left: 1px solid white;

      li:nth-child(6) {
        margin-top: 50px;
      }
    }

    .lastBox .list {
      border-left: 1px solid white;
      border-right: 1px solid white;
    }

    .list {
      padding: 80px 0 100px 40px;
      height: 100%;

      h3 {
        font-size: 18px;
        margin-bottom: 10px;
        font-weight: bold;
      }

      ul {
        li {
          line-height: 180%;

          a:hover {
            color: white;
            font-weight: 500;
          }
        }
      }
    }
  }

  .copyright {
    text-align: center;
    margin-top: -40px;
    padding: 10px;

    @include breakpoint(mobileonly) {
      margin-top: 0;
      font-size: 10px;
    }
  }
}