@import "../../../../assets/styles/variables";

.filterWrapper {
  margin: 2rem 0;

  .clearDate {
    color: $primary;
    text-decoration: underline;
  }

  .react-calendar {
    font-size: 12px;
  }
}