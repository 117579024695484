@import "../../assets/styles/variables";

.navigation {
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 20;

    .container-fluid {
        padding: 0;
    }

    .navbar {
        padding: 20px 0;

        .logo {
            padding-left: 30px;

            img {
                width: auto;
                height: 50px;
            }
        }

        .menu {
            padding-right: 15px;

            .sns {
                display: flex;
                justify-content: flex-end;
                margin-right: 20px;
                margin-bottom: 10px;

                .item {
                    padding-left: 10px;

                    img {
                        width: 20px;
                    }
                }
            }

            .gnav {
                ul {
                    display: flex;
                    list-style-type: none;

                    li {
                        padding: 2px 20px;
                        font-size: 15px;
                    }
                }
            }
        }

        .spMenu {
            display: none;
        }

        @include breakpoint(untilTablet) {
            padding: 11px 0;

            .logo {
                padding-left: 10px;

                img {
                    height: 38px;
                }
            }

            .menu {
                display: none;
            }

            .spMenu {
                display: block;
                width: 60px;
                height: 60px;
                background: #E66B00;
                position: absolute;
                top: 0;
                right: 0;
                padding: 20px 15px;
                box-sizing: border-box;
                cursor: pointer;

                .menu-trigger {
                    display: inline-block;
                    transition: all .4s;
                    box-sizing: border-box;
                    position: relative;
                    width: 30px;
                    height: 20px;

                    span {
                        &:nth-of-type(1) {
                            top: 0;
                        }

                        &:nth-of-type(2) {
                            top: calc(100% / 2 - 1px);
                        }

                        &:nth-of-type(3) {
                            bottom: 0;
                        }

                        position: absolute;
                        left: 0;
                        width: 100%;
                        height: 2px;
                        background-color: #FFF;
                        border-radius: 3px;
                    }
                }
            }
        }

    }
}

.offcanvas {
    position: relative;

    .offcanvas-header {
        display: inline-block;
        position: absolute;
        top: 1rem;
        right: 1rem;
        padding: 0;
    }

    .offcanvas-body {
        padding: 0 0 50px;
        margin: 50px 40px 0;

        .logo {
            width: 180px;
            margin: 0 0 40px;

            img {
                width: 100%;
            }
        }

        .firstMenu {
            padding-bottom: 20px;

            li {
                margin: 0 0 20px 30px;
                line-height: 140%;
                font-size: 16px;
            }
        }

        .lastMenu {
            li {
                margin: 0 0 10px 30px;
                line-height: 140%;
                font-size: 12px;
            }
        }
    }
}